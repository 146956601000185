import { getObjectValue } from "@/functions/string/getObjectValue";
import { isLogin } from "../login";
import { getApiDatas } from "@/functions/apis";

/**
 * url拦截
 * 
 * @param object to 
 * @param object next 
 */
export function userIntercept(to, next) {
    console.log('to, next', to, next)
    const routerName = getObjectValue(to, 'name', '');
    if (routerName == 'friends' && isLogin()) {
        getApiDatas("user", "imLogin", {
        }).then((apiRes) => {
            const code = getObjectValue(apiRes, 'code', '');
            if (
                typeof code != 'number' && 
                typeof code != 'string'
            ) {
                return;
            }
            const imUserKey = getObjectValue(apiRes, 'data', 'account', '');
            try {
                window.localStorage.setItem('_imUserKey', imUserKey);
            } catch (e) { console .log('e: ', e); }
            next();
            return;
        });
        return false;
    }
    return true;
}

/**
 * 得到im加密的用户key
 */
export function getImUserKey() {
    try {
        const imUserKey = window.localStorage.getItem('_imUserKey');
        if (typeof imUserKey == 'string' && '' != (imUserKey+'').trim()) {
            return imUserKey;
        }
    } catch (e) { console .log('e: ', e); }
    return '';
}