import wx from "weixin-js-sdk";
import { getObjectValue } from "../string/getObjectValue";
import { getApiDatas, saveUserInfo } from "../apis";
import { VueCookieNext } from 'vue-cookie-next';
import { getUserLastRequestUrl, isLocalWeb, isLogin } from "./login";

/**
 * 是否强制设置当前环境为微信小程序
 */
export function isForceInWechatMiniProgram() {
    if (!isLocalWeb()) {
        return false;
    }
    return false;
}

/**
 * 判断是否在微信中打开
 */
export function isInWechatApp(succ = null, fail = null) {
    if (typeof succ != 'function') succ = () => {};
    if (typeof fail != 'function') fail = () => {};

    if (isForceInWechatMiniProgram()) {
        succ();
        return true;
    }
    try {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
            try {
                wx.miniProgram.getEnv((res) => {

                    if (res.miniprogram) {
                        // 在小程序里
                        succ();
                    } else {
                        // 不在小程序中
                        fail();
                    }
                });
            } catch (e) {
                console.log('err: ', e)
            }
            return true;
        }
        fail();
    } catch (e) { console.log('err: ', e); }
    return false;
}

/**
 * 跳转到微信小程序登录页面
 */
export function gotoWechatLogin() {
    try {
        wx.miniProgram.navigateTo({
            url: '/pages/grant/authorization'
        });
    } catch (e) {
        console.log('err: ', e)
    }
}

/**
 * 微信登录成功以后跳转到上一个页面
 * 
 * @returns boolean
 */
export function wechatLoginJumpPage(next = null) {
    try {
        const isNowLoginWechat = VueCookieNext.getCookie('Wechat-Cache-authKey-now-login');
        if (typeof isNowLoginWechat == 'string' && isNowLoginWechat == '1') {
            if (typeof next == 'function') {
                const jumpUrl = getUserLastRequestUrl();
                if (jumpUrl == '/') return false;

                try {
                    VueCookieNext.setCookie(
                        'Wechat-Cache-authKey-now-login', "0", { expire: 0 }
                    );
                } catch (e) {
                    console.log('err: ', e)
                }
                next(jumpUrl);
            }
            return true;
        }
    } catch (e) {
        console.log('err: ', e)
    }
    return false;
}

/**
 * 微信小程序环境下，url访问前缀判断
 * 
 * @param object to 
 * @returns 
 */
export function isJumpToWechatLogin(to, next = null) {
    if (typeof next != 'function') next = () => { };

    const routerName = getObjectValue(to, 'name', '');
    if (typeof routerName != 'string' || '' == (routerName + '').trim()) {
        return;
    }
    if (routerName == 'login' || routerName == 'testDon') {
        // 设置返回到微信上一页以后，应该马上跳转
        try {
            VueCookieNext.setCookie(
                'Wechat-Cache-authKey-now-login', "1", { expire: 0 }
            );
        } catch (e) {
            console.log('err: ', e)
        }
        // 跳转到微信小程序登录页面
        gotoWechatLogin();
        return true;
    }
    if (isLogin()) {
        wechatLoginJumpPage(next);
        return false;
    }
    const wechatAuthCode = getObjectValue(to, 'query', 'wechatAuthCode', '');
    if (typeof wechatAuthCode == 'string' && '' != (wechatAuthCode + '').trim()) {
        getApiDatas('login', 'wxAuth', {
            code: wechatAuthCode
        }).then((apiRes) => {
            const authKey = getObjectValue(apiRes, 'data', 'authKey', '');
            if (typeof authKey == 'string' && (authKey + '').trim().length >= 5) {
                VueCookieNext.setCookie(
                    'Wechat-Cache-authKey', authKey, { expire: 0 }
                );
                wechatPhoneLogin(to, next);
            } else {
                next();
            }

            const loginUid = getObjectValue(apiRes, 'data', 'uid', '');
            const loginToken = getObjectValue(apiRes, 'data', 'token', '');
            if (
                typeof loginUid == 'string' && loginUid.trim().length >= 5 &&
                typeof loginToken == 'string' && loginToken.trim().length >= 5
            ) {
                saveUserInfo(loginUid, loginToken, {
                    success: () => {
                        if (!wechatLoginJumpPage(next)) {
                            next();
                        }
                    },
                    fail: () => {
                        alert('登录失败1');
                        next();
                    }
                });
            }
            const code = getObjectValue(apiRes, 'code', -1);
            if (0 != code) {
                next();
                return;
            }
        });
        return true;
    }
    return false;
}

/**
 * 微信小程序环境下，url访问前缀判断
 * 
 * @param object to 
 * @returns 
 */
export function wechatPhoneLogin(to, next = null) {
    if (typeof next != 'function') next = () => { };

    const wechatPhoneLoginCode = getObjectValue(to, 'query', 'wechatPhoneLoginCode', '');
    if (!(
        typeof wechatPhoneLoginCode == 'string' && '' != (wechatPhoneLoginCode + '').trim()
    )) {
        next();
        return false;
    }
    const authKey = VueCookieNext.getCookie(
        'Wechat-Cache-authKey'
    );
    if (!(typeof authKey == 'string' && (authKey + '').trim().length >= 5)) {
        next();
        return false;
    }
    getApiDatas('login', 'getPhoneNumber', {
        authKey: authKey,
        code: wechatPhoneLoginCode
    }).then((apiRes) => {
        const loginUid = getObjectValue(apiRes, 'data', 'uid', '');
        const loginToken = getObjectValue(apiRes, 'data', 'token', '');
        if (
            typeof loginUid == 'string' && loginUid.trim().length >= 5 &&
            typeof loginToken == 'string' && loginToken.trim().length >= 5
        ) {
            saveUserInfo(loginUid, loginToken, {
                success: () => {
                    if (!wechatLoginJumpPage(next)) {
                        next();
                    }
                },
                fail: () => {
                    next();
                }
            });
            return;
        }
        next();
    });
    return true;
}
