import { getObjectValue } from "./getObjectValue";

/**
 * 格式化自定义的API信息
 */
export function formatAPIData(api, options = {}) {
    if (typeof options != 'object' || null == options) {
        options = {};
    }
    if (typeof options.success != 'function') {
        options.success = () => {};
    }
    return new Promise(function(resolve, reject) {
        api().then(r => {
            let isEmptyResult = true;
            let data = {};
            try {
                data = JSON.parse(r);
                isEmptyResult = false;
            } catch (e) {
                console .log(e)
            }
            if (200 != getObjectValue(data, 'code', 0)) {
                if (isEmptyResult) {
                    reject(r);
                    return;
                }
                reject(data);
                return;
            }
            options.success(data);
            resolve(data);
        }).catch(r => {
            reject(r);
        });
    });
}

/**
 * 为数组的每行添加一个事件字段key
 */
export function addLineDataTimeKey(data, level = 1) {
    if (typeof data != 'object' || null == data) {
        return data;
    }
    if (1 === level) {
        if (data.length < 1) {
            return data;
        }
    }
    
    for (const key in data) {
        if (!Object.hasOwnProperty.call(data, key)) {
            continue;
        }
        if (2 === level) {
            if (typeof data[key] != 'object' || null == data[key] || data[key].length < 1) {
                continue;
            }
            for (const key2 in data[key]) {
                if (!Object.hasOwnProperty.call(data[key], key2)) {
                    continue;
                }
                if (typeof data[key][key2] == 'object' && null != data[key][key2]) {
                    data[key][key2]['_updateDateString'] = (new Date()).toUTCString();
                }
            }
        } else {
            if (typeof data[key] != 'object' || null == data[key]) {
                data[key] = {};
            }
            data[key]['_updateDateString'] = (new Date()).toUTCString();
        }
    }
    return data;
}

/**
 * 转化为Float
 * 
 * @param {string|int|number} n      需要转化的小数
 * @param {number} validNum          保留的有效小数部分
 */
export function parseFloatRound(n, validNum = 2) {
    if (
        typeof n != 'string' && typeof n != 'number' &&
        !(
            /^[0-9]{1,}$/.test(n) || /^[0-9]{1,}\.[0-9]{1,}$/.test(n)
        )
    ) {
        return n;
    }
    if (validNum <= 0) {
        return parseInt(n);
    }
    return parseFloat(
        parseFloat(n).toFixed(validNum)
      );
}