import { createRouter, createWebHistory } from 'vue-router';
import { isLogin, setUserLastRequestUrl, checkIsShowFullPage } from '../functions/apis-pages/login.js';
import HomeView from '../views/HomeView.vue';
import { isJumpToWechatLogin, isInWechatApp } from '@/functions/apis-pages/wechatSDKJs.js';
import { userIntercept } from '@/functions/apis-pages/pages/userIntercept.js';

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
    }
  },
  {
    path: '/about',   //关于我们
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/',
    name: 'indexNew',  //首页
    component: () => import('../views/IndexnewView.vue')
  },
  // {
  //   path: '/',
  //   name: 'download',  // 同downloadApp页面
  //   component: () => import('../views/Download.vue')
  // },
  {
    path: '/indexnew',
    name: 'index',
    component: () => import('../views/IndexnewView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      requireAuth: false
    },
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/setPayPassword', //设置支付密码
    name: 'setPayPassword',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/SetPayPassword.vue')
  },
  {
    path: '/messageList', //消息 列表
    name: 'messageList',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/MessageList.vue')
  },
  {
    path: '/set',   //设置
    name: 'set',
    meta: {
      requireAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SetView.vue')
  },
  {
    path: '/realName',   //实名认证
    name: 'realName',
    component: () => import(/* webpackChunkName: "about" */ '../views/RealName.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/account',   //账户余额
    name: 'account',
    meta: {
      requireAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AccountView.vue')
  },
  {
    path: '/mine',   //我的
    name: 'mine',
    meta: {
      requireAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/MineView.vue')
  },
  {
    path: '/cart',   //购物车列表
    name: 'cart',
    meta: {
      requireAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ShoppingCart.vue')
  },
  {
    path: '/auctionMarket',   //拍卖市场
    name: 'auctionMarket',
    component: () => import(/* webpackChunkName: "about" */ '../views/AuctionMarket.vue')
  },
  {
    path: '/auctionDetail',   //拍卖详情
    name: 'auctionDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/AuctionDetail.vue')
  },
  {
    path: '/myLand',   //我的土地
    name: 'myLand',
    meta: {
      requireAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/MyLand.vue')
  },
  {
    path: '/map',   //地图
    name: 'map',
    component: () => import(/* webpackChunkName: "about" */ '../views/MapView.vue')
  },
  {
    path: '/inviteFriends',   //邀请好友
    name: 'inviteFriends',
    meta: {
      requireAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/InviteFriends.vue')
  },
  {
    path: '/changePhone',   //修改手机号
    name: 'changePhone',
    meta: {
      requireAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ChangePhone.vue')
  },
  {
    path: '/contactUs',   //联系我们
    name: 'contactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/licenseAgreement',   //许可协议
    name: 'licenseAgreement',
    component: () => import('../views/LicenseAgreement.vue')
  },
  {
    path: '/privacyPolicy',   //隐私政策
    name: 'privacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/accountDetail',   //账户明细
    name: 'accountDetail',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/AccountDetail.vue')
  },
  {
    path: '/payment',   //支付/充值/提现页面
    name: 'payment',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/PaymentView.vue')
  },
  {
    path: '/paySuccess',   //支付/充值/提现成功
    name: 'paySuccess',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/PaySuccess.vue')
  },
  {
    path: '/payFailure',   //支付/充值/提现失败
    name: 'payFailure',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/PayFailure.vue')
  },
  {
    path: '/editProfile',   //修改头像昵称等
    name: 'editProfile',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/EditProfile.vue')
  },
  {
    path: '/editNickname',   //修改昵称
    name: 'editNickname',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/EditNickname.vue')
  },
  {
    path: '/editIntro',   //修改个人简介
    name: 'editIntro',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/EditIntro.vue')
  },
  {
    path: '/contactDetail',   //联系我们
    name: 'contactDetail',
    component: () => import('../views/ContactDetail.vue')
  },
  {
    path: '/platformIntro',   //平台介绍
    name: 'platformIntro',
    component: () => import('../views/PlatformIntro.vue')
  },
  {
    path: '/testDon',   // 测试
    name: 'testDon',
    component: () => import('../views/Test/TestView.vue')
  },
  {
    path: '/buyActivity', // 购买活动排名
    name: 'buyActivity',
    component: () => import('../views/BuyActivity.vue')
  },
  {
    path: '/digitalCollectionDetails', // 数藏详情页
    name: 'digitalCollectionDetails',
    component: () => import('../views/DigitalCollectionDetails.vue')
  },
  {
    path: '/digitalCollection', // 数藏首页
    name: 'digitalCollection',
    component: () => import('../views/DigitalCollection.vue')
  },
  {
    path: '/myDigitalCollection', // 我的藏品
    name: 'myDigitalCollection',
    component: () => import('../views/MyDigitalCollection.vue')
  },
  {
    path: '/myDigitalCollectionDetails', // 我的藏品详情
    name: 'myDigitalCollectionDetails',
    component: () => import('../views/MyDigitalCollectionDetails.vue')
  },
  {
    path: '/collectionsAirdrop', // 藏品空投
    name: 'collectionsAirdrop',
    component: () => import('../views/CollectionsAirdrop.vue')
  },
  {
    path: '/downloadApp', // APP下载
    name: 'downloadApp',
    component: () => import('../views/DownloadApp.vue')
  },
  {
    path: '/download', // APP下载
    name: 'download',
    component: () => import('../views/Download.vue')
  },
  {
    path: '/report',   // 投诉页面
    name: 'report',
    component: () => import('../views/Report/ReportView.vue')
  },
  {
    path: '/collectFragments', // 收集藏品
    name: 'collectFragments',
    component: () => import('../views/CollectFragments.vue')
  },
  {
    path: '/buildingComplex', // 收集藏品
    name: 'buildingComplex',
    component: () => import('../views/BuildingComplex.vue')
  },
  {
    path: '/buildingComplexDetails', // 数藏详情页
    name: 'buildingComplexDetails',
    component: () => import('../views/BuildingComplexDetails.vue')
  },
  {
    path: '/myBuildingComplex', // 我的建筑、土地列表
    name: 'myBuildingComplex',
    component: () => import('../views/MyBuildingComplex.vue')
  },
  {
    path: '/myBuildingComplexDetails', // 我的建筑、土地列表详情
    name: 'myBuildingComplexDetails',
    component: () => import('../views/MyBuildingComplexDetails.vue')
  },
  {
    path: '/aiDemo',   // AI demo页面
    name: 'aiDemo',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/AiDemo.vue')
  },

  {
    path: '/coCreationCommunity', // 共创首页
    name: 'coCreationCommunity',
    component: () => import('../views/CoCreationCommunity.vue')
  },
  {
    path: '/coCreationCommunityDetails', // 共创详情页
    name: 'coCreationCommunityDetails',
    component: () => import('../views/CoCreationCommunityDetails.vue')
  },
  {
    path: '/electricity', // 电力值
    name: 'electricity',
    component: () => import('../views/Electricity.vue')
  },

  {
    path: '/myCoCreationCommunity', // 我的共创
    name: 'myCoCreationCommunity',
    component: () => import('../views/MyCoCreationCommunity.vue')
  },
  // {
  //   path: '/myCoCreationCommunity', // 我的共创
  //   name: 'myCoCreationCommunity',
  //   component: () => import('../views/MyCoCreationCommunity.vue')
  // },
  // {
  //   path: '/myCoCreationCommunityDetails', // 我的共创详情
  //   name: 'myCoCreationCommunityDetails',
  //   component: () => import('../views/MyCoCreationCommunityDetails.vue')
  // },
  {
    path: '/dianCoin', // 电力值
    name: 'dianCoin',
    component: () => import('../views/DianCoin.vue')
  },
  {
    path: '/gw-product', // 电力值
    name: 'gw-product',
    component: () => import('../views/GwProduct.vue')
  },
  {
    path: '/discover', // 发现
    name: 'discover',
    component: () => import('../views/Discover.vue')
  },
  {
    path: '/friends', // 好友
    name: 'friends',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/Friends.vue')
  },

  {
    path: '/landSaleList', // 热卖土列表页面
    name: 'landSaleList',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/LandSaleList.vue')
  },

  {
    path: '/BruceLeeCard', // 李小龙卡牌
    name: 'BruceLeeCard',
    component: () => import('../views/BruceLeeCard.vue')
  },

  {
    path: '/addressEdit', // 地址编辑页
    name: 'addressEdit',
    component: () => import('../views/AddressEdit.vue')
  },
  {
    path: '/myCard', // 我的卡牌
    name: 'myCard',
    component: () => import('../views/MyCardLists.vue')
  },
  {
    path: '/myCardDetails', // 我的卡牌
    name: 'myCardDetails',
    component: () => import('../views/MyCardDetails.vue')
  },
  {
    path: '/cardSeries', // 系列卡牌
    name: 'cardSeries',
    component: () => import('../views/CardSeries.vue')
  },
  {
    path: '/cardSeriesList', // 系列卡牌列表
    name: 'cardSeriesList',
    component: () => import('../views/CardSeriesList.vue')
  },
  {
    path: '/cardSeriesListDetails', // 系列卡牌列表 下的某一个系列详情
    name: 'cardSeriesListDetails',
    component: () => import('../views/CardSeriesListDetails.vue')
  },
  {
    path: '/myCardSeriesDetails', // 我的单张数字卡
    name: 'myCardSeriesDetails',
    component: () => import('../views/MyCardSeriesListDetailsDetails.vue')
  },
  {
    path: '/cardSeriesDetails', // 单张数字卡详情说明
    name: 'cardSeriesDetails',
    component: () => import('../views/CardSeriesDetails.vue')
  },
  {
    path: '/physicalCard', // 系列卡牌 （实体）
    name: 'physicalCard',
    component: () => import('../views/PhysicalCard.vue')
  },
  {
    path: '/physicalCardList', // 系列卡牌 （实体）单个系列下的列表
    name: 'physicalCardList',
    component: () => import('../views/PhysicalCardList.vue')
  },
  {
    path: '/physicalCardDetails', // 系列卡牌 （实体）详情
    name: 'physicalCardDetails',
    component: () => import('../views/PhysicalCardDetails.vue')
  },
  {
    path: '/gzq2024', // 点真星球 × 2024 格子旗音乐节嘉年华
    name: 'gzq2024',
    component: () => import('../views/Gzq2024.vue')
  },
  {
    path: '/surname', // 点真星球 × 姓氏元宇宙
    name: 'surname',
    component: () => import('../views/Surname.vue')
  },
  {
    path: '/cardSeriesListForSurname', // 系列卡牌列表
    name: 'cardSeriesListForSurname',
    component: () => import('../views/CardSeriesListForSurname.vue')
  },
  {
    path: '/shippedList',   // 发货列表页
    name: 'shippedList',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/ShippedList.vue')
  },
  {
    path: '/deliverGoodsList', // 发货列表
    name: 'deliverGoodsList',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/DeliverGoodsList.vue')
  },
  {
    path: '/myDeliverGoodsDetail', // 发货详情页面
    name: 'myDeliverGoodsDetail',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/MyDeliverGoodsDetail.vue')
  },
  {
    path: '/ticket', // 点真星球 × 刮刮乐
    name: 'ticket',
    component: () => import('../views/Ticket.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
function beforeRouterEach(to, from, next) {
  const isShowFullPage = checkIsShowFullPage(to, next);
  if (typeof isShowFullPage == 'boolean' && false === isShowFullPage) {
    return;
  }
  if (!userIntercept(to, next)) {
    return;
  }
  setUserLastRequestUrl(to);
  if (
    typeof to == 'object' && null != to &&
    typeof to.meta == 'object' && null != to.meta &&
    typeof to.meta.requireAuth == 'boolean'
  ) {
    if (to.meta.requireAuth && !isLogin()) {
      // 必须登录, 跳转到登录
      next('/login');
      return;
    } else if (!to.meta.requireAuth && isLogin()) {
      // 登录以后，不能访问该页面 (如已经登录了，就不应该在访问登录页面)
      next('/');
      return;
    }
  }
  next();
  return;
}
router.beforeEach((to, from, next) => {
  isInWechatApp(() => {
    if (isJumpToWechatLogin(to, next)) {
      return;
    }
    beforeRouterEach(to, from, next);
  }, () => {
    beforeRouterEach(to, from, next);
  });
});
export default router;
