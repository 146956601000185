import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createDeviceDetector } from 'next-vue-device-detector'
import { VueCookieNext } from 'vue-cookie-next'
import i18n from './i18n'
import VueKonva from 'vue-konva'
import { getObjectValue } from './functions/string/getObjectValue'
import { saveUserInfo } from './functions/apis'

// set default config
VueCookieNext.config({ expire: '7d' });

router.beforeEach((to, from, next) => {
    const title = getObjectValue(to, 'meta', 'title', '');
    if (
        (typeof title == 'string' && '' != title) ||
        typeof title == 'number'
    ) {
        document.title = title+"";
    }
    const query = getObjectValue(to, 'query', {});
    if (
        typeof query == 'object' && null != query && 
        typeof query['loginUid'] == 'string' && '' != (query['loginUid']+'').trim() && 
        typeof query['loginToken'] == 'string' && '' != (query['loginToken']+'').trim()
    ) {
        saveUserInfo(query['loginUid'], query['loginToken'], {
            success: () => {
                next();
            },
            fail: () => {
                next();
            }
        });
        return;
    }
    next();
});
const device = createDeviceDetector();
createApp(App).use(i18n).use(device)
.use(VueKonva)
.use(VueCookieNext).use(router).mount('#app');
