
import { magento } from '../apis';
import { Toast } from 'vant';
import { isUserCMallOnlineUrl } from '@/config/magento';
import { VueCookieNext } from 'vue-cookie-next';
import { getObjectValue } from '../string/getObjectValue';

/**
 * 得到模板对象
 * 
 * @param object options 
 * @returns 
 */
export function getTplThis(options) {
    if (typeof options != 'object' || null == options) options = {};
    if (typeof options.t != 'function') options.t = () => { };
    if (typeof options.router != 'object' || null == options.router) options.router = {};
    if (typeof options.router.push != 'function') options.router.push = () => { };
    if (typeof options.isShowToast != 'boolean') options.isShowToast = false;
    return options;
}

export function loginNow(email, password, options = {}) {
    options = getTplThis(options);

    if (options.isShowToast) {
        Toast.loading({
            message: options.t("loading"),
            forbidClick: true,
        });
    }
    return new Promise((resolve) => {
        magento.guest
            .login({
                email,
                password,
            })
            .then(token => {
                if (typeof token != 'string') {
                    resolve(false);
                    return;
                }
                resolve(token);
                return;
            })
            .catch(() => {
                resolve(false);
                return;
            });
    }).then((token) => {
        if (!token) {
            // 失败
            if (options.isShowToast) {
                Toast.fail(options.t("loginFailed"));
            }
            return;
        }
        // 登录成功
        if (options.isShowToast) {
            Toast.success('');
        }
        magento.setCustomerToken(token);
        options.router.push('/');
        return token;
    })
}

/**
 * 判断是否登录
 * 
 * @returns bool
 */
export function isLogin() {
    return magento.isLogin();
}

/**
 * 退出登录
 * 
 * @returns bool
 */
export function logout() {
    return magento.logout();
}

const userLastRequestUrlCacheKey = 'RequestUrl';
/**
 * 设置用户最后一次访问的url (不包括登录页)
 * 
 * @param object to 
 * @returns 
 */
export function setUserLastRequestUrl(to) {
    if (typeof to != 'object' || null == to) {
        return;
    }
    let currentUrlName = getObjectValue(to, 'name', '');
    if (typeof currentUrlName != 'string' || currentUrlName.trim() == '') {
        currentUrlName = '';
    }
    if (currentUrlName == 'login' || currentUrlName == 'testDon') {
        return;
    }
    const cacheKey = userLastRequestUrlCacheKey + '-' + (isUserCMallOnlineUrl ? 1 : 0);
    const fullPath = getObjectValue(to, 'fullPath', '');

    const cacheValue = VueCookieNext.getCookie(cacheKey);
    if (typeof cacheValue == 'string' && cacheValue == fullPath) {
        return;
    }
    VueCookieNext.setCookie(
        cacheKey, fullPath, { expire: 60 * 60 * 24 * 2 }
    );
}

/**
 * 得到用户最后一次访问的url (不包括登录页)
 * 
 * @returns 
 */
export function getUserLastRequestUrl() {
    const cacheKey = userLastRequestUrlCacheKey + '-' + (isUserCMallOnlineUrl ? 1 : 0);
    const cacheValue = VueCookieNext.getCookie(cacheKey);
    if (typeof cacheValue == 'string' && cacheValue.trim() != '') {
        return cacheValue;
    }
    return '/';
}

/**
 * 检查是否显示首页
 */
export function checkIsShowFullPage(to, next) {
    const query = getObjectValue(to, 'query', {});
    if (!isCanShowTrueHome(to, next)) {
        return false;
    }
    let appVersion = getObjectValue(query, 'appVersion', '');
    if (
        !(
            (typeof appVersion == 'number' || typeof appVersion == 'string') &&
            (/^[0-9]{1,8}$/gi.test(appVersion+'') || /^[0-9]{1,4}\.[0-9]{1,4}$/gi.test(appVersion+''))
        )
    ) {
        return true;
    }
    appVersion = parseFloat(''+appVersion);
    let deviceModel = getObjectValue(query, 'deviceModel', '');
    if (typeof deviceModel != 'string') deviceModel = '';
    deviceModel = deviceModel.trim();
    if ((
        appVersion <= 2.281 ||
        appVersion <= 2.28 ||
        appVersion <= 2.27
    ) && (
        /^mac/gi.test(deviceModel) ||
        /^iphone/gi.test(deviceModel) ||
        /^ipad/gi.test(deviceModel)
    )) {
        // 不让访问
        // next({
        //     name: 'platformIntro'
        // });
        // return false;
    }
    return true;
}

/**
 * 是否显示正确的首页
 */
function isCanShowTrueHome(to, next) {
    const path = getObjectValue(to, 'path', '');
    if (typeof path != 'string' || (
        '' != (path+'').trim() && 
        '/' != (path+'').trim()
    )) {
        return true;
    }
    const query = getObjectValue(to, 'query', {});
    let appVersion = getObjectValue(query, 'appVersion', '');
    if (
        (typeof appVersion == 'number' || typeof appVersion == 'string') &&
        (/^[0-9]{1,8}$/gi.test(appVersion+'') || /^[0-9]{1,4}\.[0-9]{1,4}$/gi.test(appVersion+''))
    ) {
        return true;
    }
    if (isLocalWeb()) {
        return true;
    }
    // 不让访问 由于网安部的要求 暂时隐藏首页 ok后恢复
    // next({
    //     name: 'download'
    // });
    console .log('next: ', next)
    return true;
}

/**
 * 是否是本地网站
 * 
 * @returns
 */
export function isLocalWeb() {
    try {
        if (
            (location.host+'').indexOf('localhost') === 0
        ) {
            return true;
        }
    } catch (e) {
        console .log('e: ', e)
    }
    return false;
}

/**
 * 是否显示新的页面
 * 
 * @param string typeStr 
 * @returns 
 */
export function isShowNewPage(typeStr = 'buildingComplex') {
    if (typeof typeStr != 'string') {
        return false;
    }
    // if (typeStr == 'buildingComplex' && isLocalWeb()) { // && isLocalWeb() -> 生产隐藏显示
    if (typeStr == 'buildingComplex') {
            return true;
    // } else if (typeStr == 'digitalCollection' && isLocalWeb()) { // 更名共创社区
    } else if (typeStr == 'digitalCollection') {
        return true;
    } if (typeStr == 'coCreationCommunity') {
        return true;
    } else if (/^version-[0-9]{1,10}$/gi.test(typeStr)) {
        if (isLocalWeb()) {
            return true;
        }
        try {
            const versionCode = parseInt(typeStr.split('-')[1]);
            if (versionCode >= 2023070801) {
                return true;
            }
        } catch (e) {console .log('e: ', e)}
        return false;
    }
    return false;
}
