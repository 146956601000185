/* eslint-disable no-console */
import axios from 'axios';
import { VueCookieNext } from 'vue-cookie-next';
import admin from './lib/admin';
import guest from './lib/guest';
import customer from './lib/customer';
import { ADMIN_TYPE, CUSTOMER_TYPE } from './types';
import { magentoOptions, isUserCMallOnlineUrl } from '../../config/magento';
import { getObjectValue } from '@/functions/string/getObjectValue';
import { Toast } from 'vant';

export const cookie = VueCookieNext;
export function isNumber(n) {
  return typeof n === 'number' && !Number.isNaN(n) && Number.isFinite(n);
}

export function translate() {
  
}

const defaultOptions = {
  url: null,
  userAgent: 'YuSheng Magento Library',
  home_cms_block_id: '',
  authentication: {
    integration: {
      access_token: undefined,
    },
  },
};

class Magento {
  init(options) {
    this.configuration = { ...defaultOptions, ...options };
    this.getCustomerToken();
    this.base_url = this.configuration.url.replace(
      /[\\/]{1,}$/, ''
    );
    this.root_path = `/${this.configuration.store}`;
    this.admin = admin(this);
    this.guest = guest(this);
    this.customer = customer(this);
    if (this.configuration.authentication.integration.access_token) {
      this.access_token = this.configuration.authentication.integration.access_token;
    } else {
      throw integrationTokenError();
    }
  }

  post(path, params, data, type = ADMIN_TYPE) {
    return this.send(path, 'POST', params, data, type);
  }

  put(path, params, data, type = ADMIN_TYPE) {
    return this.send(path, 'PUT', params, data, type);
  }

  get(path, params, data, type = ADMIN_TYPE) {
    return this.send(path, 'GET', params, data, type);
  }

  delete(path, params, data, type = ADMIN_TYPE) {
    return this.send(path, 'DELETE', params, data, type);
  }

  send(path, method, params, data, type) {
    let url = `${this.base_url}${this.root_path}${path}`;
    if (!isUserCMallOnlineUrl) {
      url = `/${this.root_path}${path}`.replace(
        /^[\\/]{1,}/, '/'
      );
    }
    console .log('this.base_url', url)
    
    
    const headers = {
      // 'User-Agent': this.configuration.userAgent,
      'Content-Type': 'application/json',
    };

    this.customerToken = this.getCustomerToken();
    if (this.access_token && type === ADMIN_TYPE) {
      headers.Authorization = `Bearer ${this.access_token}`;
    } else if (typeof this.customerToken == 'string' && this.customerToken.trim() != '' && type === CUSTOMER_TYPE) {
      headers.Authorization = `Bearer ${this.customerToken}`;
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        method,
        headers,
        params,
        data,
        withCredentials: false
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 404 && error.response.data == null) {
              reject();
              return;
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            const noReponseError = noResponseFromServerError();
            reject(noReponseError);
            return;
          } else {
            // Something happened in setting up the request that triggered an Error
          }
          let customError;
          if (
            typeof error.response.data === 'object' &&
            error.response.data !== null
          ) {
            customError = Magento.extractErrorMessage(error.response.data);
          } else {
            customError = pageNotFoundError();
          }
          if (typeof customError == 'object' && null != customError) {
            customError.responseData = error.response.data;
          }
          if (
            typeof customError.responseData == 'object' && 
            null != customError.responseData &&
            typeof customError.responseData.code == 'number'
          ) {
            resolve(customError.responseData);
            return;
          }
          reject(customError);
        });
    });
  }

  static extractErrorMessage(data) {
    const { parameters } = data;
    let { message } = data;

    if (parameters && Array.isArray(parameters) && parameters.length > 0) {
      parameters.forEach((item, index) => {
        message = message.replace(`%${index + 1}`, item);
      });
    } else if (parameters && parameters instanceof Object) {
      Object.keys(parameters).forEach(parameter => {
        message = message.replace(`%${parameter}`, parameters[parameter]);
      });
    }

    return { message };
  }

  isConfigured() {
    return this.access_token != null;
  }

  setStoreConfig(config) {
    this.storeConfig = config;
  }

  isLogin() {
    const userInfo = getUserInfo();
    return null == userInfo ? false : true;
  }


  logout() {
    cookie.removeCookie(
      'UserCustomerLoginInfo-'+(isUserCMallOnlineUrl?1:0)
    );
  }

  getCustomerToken() {
    let token = this.customerToken;
    if ((
      typeof token != 'string' && typeof token != 'number'
    ) || '' == (token+'').trim()) {
      token = cookie.getCookie(
        'UserCustomerToken-'+(isUserCMallOnlineUrl?1:0)
      );
    }
    if ((
      typeof token != 'string' && typeof token != 'number'
    ) || '' == (token+'').trim()) {
      token = '';
    }
    token = (token+'').trim();
    this.customerToken = token;
    return token;
  }

  setCustomerToken(token) {
    if ((
      typeof token != 'string' && typeof token != 'number'
    ) || '' == (token+'').trim()) {
      return;
    }
    this.customerToken = token;
    cookie.setCookie(
      'UserCustomerToken-'+(isUserCMallOnlineUrl?1:0), 
      token, { expire: 60 * 60 * 24 * 1 }
    );
  }

  getBaseUrl() {
    return this.base_url || '';
  }

  getMediaUrl() {
    if (
      typeof this.base_url == 'string' && 
      (
        typeof this.storeConfig != 'object' || null == this.storeConfig || 
        typeof this.storeConfig.base_media_url != 'string'
      )
    ) {
      return this.base_url+'media/';
    }
    return `${this.storeConfig.base_media_url}`;
  }

  getProductMediaUrl() {
    return this.getMediaUrl()+'catalog/product';
  }

  getHomeData() {
    if (isNumber(this.configuration.home_cms_block_id)) {
      return this.admin.getCmsBlock(this.configuration.home_cms_block_id);
    }
    throw homeCmsBlockError();
  }
}

function homeCmsBlockError() {
  const name = translate('errors.homeCmsBlockId');
  const message = translate('errors.homeCmsBlockIdMessage');
  return createError(name, message);
}

function integrationTokenError() {
  const name = translate('errors.integrationTokenRequired');
  const message = translate('errors.integrationTokenRequiredMessage');
  return createError(name, message);
}

function noResponseFromServerError() {
  const name = translate('errors.noResponseFromServer');
  const message = translate('errors.noResponseFromServerMessage');
  return createError(name, message);
}

function pageNotFoundError() {
  const name = translate('errors.404error');
  const message = translate('errors.404errorMessage');
  return createError(name, message);
}

function createError(name, message) {
  const error = new Error();
  error.name = name;
  error.message = message;
  return error;
}

// Constants
export const CUSTOMER_TOKEN = 'customerToken';
export const CURRENCY_CODE = 'currencyCode';

export const magento = new Magento();
magento.init(magentoOptions);

function isTureUserObject(userInfo) {
  if (
    typeof userInfo != 'object' || null == userInfo ||
    (typeof userInfo.uid != 'string' && typeof userInfo.uid != 'number') ||
    '' === (userInfo.uid+'').trim() || 
    (typeof userInfo.token != 'string' && typeof userInfo.token != 'number') ||
    '' === (userInfo.token+'').trim()
  ) {
    return false;
  }
  return true;
}
/**
 * 得到用户的登录信息
 */
export function getUserInfo() {
  try {
    let userInfo = cookie.getCookie(
      'UserCustomerLoginInfo-'+(isUserCMallOnlineUrl?1:0)
    );
    if (!isTureUserObject(userInfo)) {
      userInfo = JSON.parse(userInfo);
    }
    if (!isTureUserObject(userInfo)) {
      return null;
    }
    return userInfo;
  } catch (e) {
    console .log('从cookie里无法得到登录信息: ', e)
  }
  return null;
}

/**
 * 保存用户的相关登录信息
 */
export function saveUserInfo(uid, userToken, options = {}) {
  if (typeof options != 'object' || null == options) options = {};
  if (typeof options.success != 'function') options.success = () => {};
  if (typeof options.fail != 'function') options.fail = () => {};
  
  if (
    (typeof uid != 'number' && typeof uid != 'string') ||
    '' == (uid+'').trim() || (uid+'').length > 32
  ) {
    options.fail();
    return;
  }
  try {
    const saveData = {uid, token: userToken};
    cookie.setCookie(
      'UserCustomerLoginInfo-'+(isUserCMallOnlineUrl?1:0), 
      JSON.stringify(saveData), { expire: 60 * 60 * 24 * 30 }
    );
    options.success(saveData);
    return;
  } catch (e) {
    console .log('保存登录信息失败', e);
  }
  options.fail();
  return;
}

/**
 * 得到apitoken
 */
function getApiTokenString() {
  const tokenResults = {
          code: -1,
          msg: '错误的token',
          apiToken: ''
        };
  return new Promise((r) => {
    magento.customer.getToken().then(token => {
      if (!(typeof token != 'string' || (token+'').trim() == '')) {
        tokenResults.code = 1;
        tokenResults.apiToken = token;
        tokenResults.msg = '';
        r(tokenResults);
        return tokenResults;
      }
      magento.customer.getToken().then(token => {
        if (!(typeof token != 'string' || (token+'').trim() == '')) {
          tokenResults.code = 1;
          tokenResults.apiToken = token;
          tokenResults.msg = '';
        }
        r(tokenResults);
        return tokenResults;
      });
    });
  });
}

/**
 * 得到API的数据
 */
export async function getApiDatas(apiName, apiAction, bodyData = {}, options = {}) {
  if (typeof bodyData != 'object' || null == bodyData) bodyData = {};
  if (typeof options != 'object' || null == options) options = {};
  if (typeof options.isShowLoading != 'boolean' || false == options.isShowLoading) {
    options.isShowLoading = false;
  }
  if (typeof options.loadType != 'number' || options.loadType <= 0) {
    options.loadType = 0;
  }
  
  // loading显示的文本
  if (
    typeof options.loadingText != 'string' && 
    typeof options.loadingText != 'number'
  ) {
    options.loadingText = '加载中...';
  }
  options.loadingText += '';

  // 加载loading显示时间, 如果为0的话, 意味着一直显示
  // 默认显示30秒自动小时
  if (typeof options.loadingDuration != 'number') {
    options.loadingDuration = 1000 * 30;
  }

  if (true == options.isShowLoading) {
    let loadOptions = {
      message: options.loadingText,
      forbidClick: true,
      duration: options.loadingDuration,
      className: 'my-custom-loading my-custom-loading-'+options.loadType
    };
    if (1 == options.loadType) {
      const loadList = [
        require('@/assets/images/loads/loading-fragment1.gif'),
        require('@/assets/images/loads/loading-fragment2.gif'),
        require('@/assets/images/loads/loading-fragment3.gif')
      ];
      const loadSelectedIndex = Math.floor(Math.random()*loadList.length + 1) - 1;
      loadOptions.icon = loadList[loadSelectedIndex];
      loadOptions.iconSize = 200;
    }
    Toast.loading(loadOptions);
  }
  return new Promise((r) => {
    getApiTokenString().then(tokenResults => {
      if ('' == tokenResults.apiToken) {
        r(tokenResults);
        return tokenResults;
      }
      const userInfo = getUserInfo();
      magento.customer.getApiDatas(apiName, Object.assign({}, {
        "apiName": apiName,
        "apiAction": apiAction,
        "apiToken": tokenResults.apiToken
      }, null == userInfo ? {} : userInfo, bodyData)).then(apiRes => {
        r(apiRes);
        return apiRes;
      }).catch(() => {
        r({});
        return {};
      });
    });
  }).then(apiRes => {
      if (true == options.isShowLoading) {
        Toast.clear();
      }
      if (
        typeof apiRes != 'object' || null == apiRes
      ) {
        apiRes = {};
      }
      let code = getObjectValue(apiRes, 'code', -1);
      // AUTH 验证失败时返回-444则跳登录
      if (code == -444) {
        magento.logout();
        try {
          this.$router.push({ name: 'login' });
        } catch (e) {
          console .log('err', e)
        }
        return ;
      }
      if (
        !/[\\-]{0,1}[0-9]{1,}$/.test((code+''))
      ) {
        code = -1;
      }
      let msg = getObjectValue(apiRes, 'msg', '');
      if (
        typeof msg != 'string' && typeof msg != 'number'
      ) {
        msg = '';
      }
      return Object.assign({}, apiRes, {
        code, msg, data: getObjectValue(apiRes, 'data', null)
      });
  });
}