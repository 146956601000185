import { getObjectValue } from '@/functions/string/getObjectValue';
import { CUSTOMER_TYPE } from '../../types';

export default magento => ({
  getToken: (isFromCache = true) => {
    return new Promise((r) => { 
      const token = magento.getCustomerToken();
      if ('' != token && true === isFromCache) {
        r(token);
        return token;
      }
      magento.customer.getApiDatas('token', {
        "apiName": "token",
        "apiAction": "getToken",
        "appId": "lightverse_001",
        "secret": "8f88574779b86ae47ae029a869c4f962",
      }).then(data => {
        const apiToken = getObjectValue(data, 'data', 'apiToken', '');
        if (
          typeof apiToken != 'string' || apiToken.length <= 10
        ) {
          r('');
          return '';
        }
        magento.setCustomerToken(apiToken);
        r(apiToken);
        return apiToken;
      }).catch(() => {
        r('');
        return '';
      });
    });
  },
  getApiDatas: (apiName, bodyData = {}) => {
    return magento.post('?'+apiName, undefined, bodyData, CUSTOMER_TYPE);
  }
});
