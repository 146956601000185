/**
 * Magento Settings for the app,
 * Follow instructions: https://github.com/alexakasanjeev/magento_react_native/wiki/Setup
 *
 * url                     : Base url of the magento website
 * home_cms_block_id       : Block id which conatin json data,
 *                           which will be shown in Home screen
 * store                   : store code // Stores > All Stores > Store View > Code
 * password_reset_template : Email template name in magento backend,
 *                           use for sending password reset e-mail
 * access_token            : Token to access magento API, without it
 *                           app won't work
 */

import envOptions from "./process/env.config";

let envStatus = 1;
if (typeof envOptions.isUserCMallOnlineUrl == 'boolean' && 
    !envOptions.isUserCMallOnlineUrl) {
      envStatus = 0;
}
if (typeof envOptions.isUserCMallOnlineUrl == 'number' && 
  -1 == envOptions.isUserCMallOnlineUrl) {
  // 处于local环境
  envStatus = -1;
}

export const isUserCMallOnlineUrl = envStatus == 1 ? true : false;
export const magentoOptions = Object.assign({}, {
  url: 'https://lv.dianzhenshenghuo.com', // make sure you have trail slash in the end
  home_cms_block_id: 64, // required int
  store: 'lv-api',
  password_reset_template: 'email_reset', // This is required in order to reset password link to work
  authentication: { 
    integration: {
      access_token: 'yty2wsauqcfjkl3po3bixg1iidetqrat',
    }
  }
}, !isUserCMallOnlineUrl ? (
  envStatus == -1 ? {
    url: '',
    store: 'test-lv-api'
  } :  {
    url: '',
    store: 'dev-lv-api'
  }
) : {});

/**
 * Magento 2 REST API doesn't return currency symbol,
 * so manually specify all currency symbol(that your store support)
 * along side their currency code.
 */

export const currencySymbols = Object.freeze({
  USD: '$',
  EUR: '€',
  AUD: 'A$',
  GBP: '£',
  CAD: 'CA$',
  CNY: 'CN¥',
  JPY: '¥',
  SEK: 'SEK',
  CHF: 'CHF',
  INR: '₹',
  KWD: 'د.ك',
  RON: 'RON',
});
